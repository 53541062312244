const scrollEvent = () => {
    const clientHeight = document.documentElement.clientHeight;
    const items = document.getElementsByClassName('animate-item');
    const offsetTop = document.documentElement.scrollTop + clientHeight;

    let check = true;
    for (const item of items) {
        if (item.offsetTop < offsetTop) {
            if (!item.classList.contains('animated')) {
                item.classList.add('animated');
            }
        }

        if (!item.classList.contains('animated')) {
            check = false;
        }
    }

    if (check) {
        window.removeEventListener('scroll', scrollEvent);
    }
}

export default scrollEvent;