<script>
export default {
    name: "ModalContainer"
}
</script>

<template>
    <div
        id="digital_highway"
        data-bo-hw-lead-button="hidden">
    </div>
</template>