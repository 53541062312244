<script>
import ServiceBlock from '@components/elements/service-block.vue'

export default {
    name: "Services",
    props: {
        services: {
            type: Array,
            default: () => []
        },
    },
    components: {
        ServiceBlock
    },
}
</script>

<template>
    <div id="services" class="services">
        <div class="services-title">Разные сервисы для решения разных задач</div>
        <div class="services-scrollable">
            <div class="services-container">
                <ServiceBlock
                    v-for="(elem, i) in services"
                    :key="i"
                    :wide="services.length < 4"
                    :title="elem.title"
                    :description="elem.description"
                    :background="elem.background"
                    :link="elem.link"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import '@design/sections/services.scss';
</style>