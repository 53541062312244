<script>
export default {
    name: "CookiesWarning",
    data() {
        return {
            permissionKey: 'cookies-permission',
            noPermission: false,
        }
    },
    methods: {
        getPermission() {
            const permission = localStorage.getItem(this.permissionKey);
            if (!permission) {
                this.noPermission = true;
            }
        },
        createPermission() {
            localStorage.setItem(this.permissionKey, "true");
            this.noPermission = false;
        }
    },
    mounted() {
        this.getPermission();
    }
}
</script>

<template>
    <div
        v-if="noPermission"
        class="cookies-warning-container"
    >
        <div class="cookies-warning">
            <div class="cookies-warning-text">
                Мы используем cookies для улучшения работы нашего сайта. Продолжая использовать сайт, Вы выражаете свое согласие на обработку файлов cookies, а также подтверждаете факт ознакомления с
                <a
                    href="https://moskva.mts.ru/personal/cookie"
                    target="_blank"
                >
                    «Политикой обработки файлов cookies»
                </a>
            </div>
            <button
                class="cookies-warning-button"
                @click="createPermission"
            >
                Принять
            </button>
        </div>
    </div>
</template>

<style lang="scss">
    @import '@design/elements/cookies-warning.scss';
</style>