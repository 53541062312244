<script>
import Article from '@components/elements/article.vue';

export default {
    name: "ArticleSlider",
    props: {
        slides: {
            type: Array,
            default: () => []
        },
    },
    components: {
        Article
    },
    data() {
        return {
            slideStep: 996,
        };
    },
    methods: {
        slidePrev() {
            const sliderContainer = this.$refs['slider-container'];
            const scrollLeftVal = sliderContainer.scrollLeft;
            sliderContainer.scrollTo({
                top: 0,
                left: scrollLeftVal - this.slideStep,
                behavior: 'smooth'
            });
        },
        slideNext() {
            const sliderContainer = this.$refs['slider-container'];
            const scrollLeftVal = sliderContainer.scrollLeft;
            sliderContainer.scrollTo({
                top: 0,
                left: scrollLeftVal + this.slideStep,
                behavior: 'smooth'
            });
        },
    }
}
</script>

<template>
    <div class="slider">
        <div
            class="slider-arrow slider-arrow-prev"
            @click="slidePrev"
        ></div>
        <div
            class="slider-slides-container"
            ref="slider-container"
        >
            <div
                class="slider-slides"
            >
                <Article
                    v-for="(article, i) in slides"
                    :key="i"
                    :image="article.image"
                    :image-is-icon="article.imageIsIcon"
                    :link="article.link"
                    :date="article.date"
                    :text="article.text"
                />
            </div>
        </div>
        <div
            class="slider-arrow slider-arrow-next"
            @click="slideNext"
        ></div>
    </div>
</template>

<style lang="scss">
    @import '@design/elements/article-slider.scss';
</style>