<script>
export default {
    name: "Ticker",
    props: {
        rows: {
            type: Array,
            default: () => []
        },
    },
}
</script>

<template>
    <div class="ticker-page-block">
        <div class="ticker-container">
            <div class="ticker">
                <div
                    v-for="(row, i) in rows"
                    :key="i"
                    class="ticker-row"
                >
                    <div
                        v-for="(elem, j) in row"
                        :key="j"
                        class="ticker-row-elem"
                    >
                        {{ elem }}
                    </div>
                </div>
            </div>
            <div class="ticker">
                <div
                    v-for="(row, i) in rows"
                    :key="i"
                    class="ticker-row"
                >
                    <div
                        v-for="(elem, j) in row"
                        :key="j"
                        class="ticker-row-elem"
                    >
                        {{ elem }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import '@design/elements/ticker.scss';
</style>