<script>
    export default {
        name: "FullSiteFooter",
        data() {
            return {
                currentYear: new Date().getFullYear()
            }
        }
    }
</script>

<template>
  <div class="row">
    <div class="col p-0">
      <div class="full-site-footer">
        <div class="footer-wrap">
          <div class="row px-4">
            <div class="col-12">
              <p>Инструкция по подключению:</p>
            </div>
          </div>
          <div class="row mt-2 px-4">
            <div class="col-12 col-md-5 col-lg-3 mb-3">
              <ol class="mt-1">
                <li>Напишите нам</li>
                <li>Заключите договор</li>
                <li>Оплатите</li>
                <li>Используйте сервис по ссылке</li>
              </ol>
            </div>
            <div class="col-12 col-md-7 col-lg-5 mb-3">
              <p>Стоимость рассчитывается индивидуально.
                Напишите нам, опишите вашу задачу и мы предложим оптимальное решение,
                а также сделаем для вас предварительный расчёт</p>
            </div>
            <div class="col-12 col-lg-4 d-flex align-items-center">
              <div class="btn">
                Попробовать
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p>© {{ currentYear }}  ПАО «МТС».  18+</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss">
@import '@design/components/full-site-footer.scss';
</style>