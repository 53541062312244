<script>

export default {
    name: "Investment",
    data() {
        return {
            supportBrowsers: false,
        };
    },
    created() {
        const browser = this.getBrowser();
        if ((browser.name === 'Chrome' && browser.version > 98) ||
            (browser.name === 'Opera' && browser.version > 79)) {
            this.supportBrowsers = true
        }
    },
    methods: {
        getBrowser() {
            const ua = navigator.userAgent;
            let tem;
            let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if (/trident/i.test(M[1])) {
                tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
                return 'IE ' + (tem[1] || '');
            }
            if (M[1] === 'Chrome') {
                tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
                if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
            }
            M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
            if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
            return {
                name: M[0],
                version: M[1]
            };
        },
    }
}
</script>

<template>
    <div id="investments" class="investments animate-item">
        <div class="investments-content">
            <div class="block-header mb-6">
                <h2>Почему мы?</h2>
            </div>
            <div class="block-content">
                <div class="block-item">
                    <div class="block-item-blur" :class="{ 'active': supportBrowsers }"></div>
                    <div class="block-item-content">
                        <h3>Точно</h3>
                        <ul>
                            <li>Высокие точность и охват</li>
                            <li>Всегда актуальные данные</li>
                            <li>Нужна конкретная выборка? Сделаем.</li>
                        </ul>
                    </div>
                </div>
                <div class="block-item">
                    <div class="block-item-blur" :class="{ 'active': supportBrowsers }"></div>
                    <div class="block-item-content">
                        <h3>Быстро</h3>
                        <ul>
                            <li>Лёгкий и оперативный доступ к данным</li>
                            <li>Данные за прошлые периоды</li>
                            <li>Вся территория России*</li>
                        </ul>
                        <div class="block-footer block-footer-mobile">
                            <p>* - доступ к данным в регионах присутствия МТС</p>
                        </div>
                    </div>
                </div>
                <div class="block-item">
                    <div class="block-item-blur" :class="{ 'active': supportBrowsers }"></div>
                    <div class="block-item-content">
                        <h3>Удобно</h3>
                        <ul>
                            <li>Удобный интерфейс</li>
                            <li>Исследования и мониторинг</li>
                            <li>Дашборды и аналитика</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="block-footer block-footer-desktop">
                <p>* - доступ к данным в регионах присутствия МТС</p>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import '@design/sections/investment.scss';
</style>