<script>
export default {
    name: "ServiceBlock",
    props: {
        wide: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ""
        },
        description: {
            type: String,
            default: ""
        },
        background: {
            type: String,
            default: ""
        },
        link: {
            type: String,
            default: ""
        },
    },
}
</script>

<template>
    <a
        :href="link"
        class="service-block"
        :class="{ wide: wide }"
        :style="{ backgroundImage: `url('/assets/images/landing/tabs/${background}.svg')` }"
    >
        <div class="service-block-title">{{ title }}</div>
        <div class="service-block-description">{{ description }}</div>
    </a>
</template>

<style lang="scss">
    @import '@design/elements/service-block.scss';
</style>