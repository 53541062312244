<script>
import { dataLayerEvent } from '@utils/data-layer.js';

export default {
    name: "Question",
    methods: {
        buttonClickEvent() {
            dataLayerEvent({
                eventId: 9,
                event: 'vntGeo',
                eventCategory: 'glavnaya',
                eventLabel: 'ostavit_zayavku',
                eventAction: 'element_click',
                buttonLocation: 'question'
            });
        }
    }
}
</script>

<template>
    <div id="question" class="question">
        <div class="block-wrap">
            <div class="block-content">
                <h3>Остались вопросы?</h3>
                <div class="block-content-body d-flex justify-content-between">
                    <p>Расскажем, покажем и поможем выбрать наиболее подходящий для вас сервис</p>
                    <div class="question-btn">
                        <button
                            class="btn"
                            data-product="GeoEffect"
                            data-vitrine="Геоэффект"
                            data-btn-view="hidden"
                            data-bo-hw-lead-button="true"
                            data-bo-required-inn="true"
                            data-bo-not-required="email"
                            data-bo-subline=""
                            data-btn-text="Оставить заявку"
                            @click="buttonClickEvent"
                        >
                            <img src="https://digitalb2b.mts.ru/widget/static/formPopup.skeleton.svg" alt="Загрузка" width="270" height="52" class="bo-widget-skeleton-btn"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import '@design/sections/question.scss';
</style>