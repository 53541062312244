<script>
import ArticleSlider from '@components/elements/article-slider.vue';

export default {
    name: "Articles",
    components: {
        ArticleSlider,
    },
    data() {
        return {
            articlesList: [
                {
                    image: '1prime.webp',
                    imageIsIcon: false,
                    date: '08.06.2024',
                    text: 'Число участников ПМЭФ выросло на четверть за год',
                    link: 'https://1prime.ru/20240608/pmef-848993570.html',
                },
                {
                    image: 'ysia.webp',
                    imageIsIcon: false,
                    date: '12.07.2024',
                    text: 'Аналитика МТС: на игры "Дети Азии" чаще остальных зарубежных гостей приезжали жители Китая',
                    link: 'https://ysia.ru/analitika-mts-na-igry-deti-azii-chashhe-ostalnyh-zarubezhnyh-gostej-priezzhali-zhiteli-kitaya/',
                },
                {
                    image: 'Message.svg',
                    imageIsIcon: true,
                    date: '05.07.2024',
                    text: 'Аналитика МТС: на Дягилевский фестиваль в Перми жители Китая приезжали чаще остальных зарубежных гостей',
                    link: 'https://www.cnews.ru/news/line/2024-07-05_analitika_mts_na_dyagilevskij',
                },
                {
                    image: 'Favorite.svg',
                    imageIsIcon: true,
                    date: '04.07.2024',
                    text: 'МТС: каждый второй посетитель фестиваля "Динтотерра" приехал из другого региона',
                    link: 'https://www.cnews.ru/news/line/2024-07-04_mts_kazhdyj_vtoroj_posetitel',
                },
                {
                    image: 'tsn.webp',
                    imageIsIcon: false,
                    date: '23.07.2024',
                    text: 'Аналитика МТС: фестиваль "Толстой" в Ясной Поляне расширил географию до 35 стран мира',
                    link: 'https://www.tsn24.ru/2024/07/23/312434-analitika-mts-festival-tolstoy-v-yasnoy-polyane-rasshiril-geografiyu-do-35-stran-mira/',
                },
            ]
        }
    }
}
</script>

<template>
    <div id="articles" class="articles">
        <div class="articles-container">
            <div class="articles-title">Что о нас пишут</div>
            <div class="articles-list">
                <ArticleSlider :slides="articlesList" />
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import '@design/sections/articles.scss';
</style>