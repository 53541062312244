<script>
export default {
    name: "FaqQuestion",
    props: {
        title: {
            type: String,
            default: ""
        },
        text: {
            type: String,
            default: ""
        },
    },
    data() {
        return {
            questionOpened: false,
        };
    },
    methods: {
        toggleQuestion() {
            this.questionOpened = !this.questionOpened;
        }
    }
}
</script>

<template>
    <div class="faq-question">
        <div
            class="faq-question-header"
            @click="toggleQuestion"
        >
            <div class="faq-question-header-text">{{ title }}</div>
            <div
                class="faq-question-header-arrow"
                :class="{ opened: questionOpened }"
            ></div>
        </div>
        <div
            v-if="questionOpened"
            class="faq-question-body"
        >
            {{ text }}
        </div>
    </div>
</template>

<style lang="scss">
    @import '@design/elements/faq-question.scss';
</style>